.homeContainer {
  min-height: calc(100% - 12.6rem);
  position: relative;
  .diytooltip {
    z-index: 2;
    position: fixed;
    right: 10%;
    top: 12rem;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.5s, transform 0.5s;
    pointer-events: none; /* 防止鼠标交互 */
    background: #ffffff;
    box-shadow: 0 0 20px #0000000d;
    border-radius: 10px;
    padding: 3rem 4rem;
    line-height: 1;
    .tipTitle {
      font-weight: 700;
      font-size: 1.6rem;
      color: #261a1a;
    }

    .tipSubTitle {
      margin-top: 1.3rem;
      margin-bottom: 2.5rem;
      font-size: 1.2rem;
      color: #999;
    }

    .tipErweima {
      width: 17rem;
      height: 17rem;
    }

    .tipFooter {
      margin-top: 1.8rem;
      font-weight: 300;
      font-size: 1.4rem;
      color: #bbb;
      text-align: center;
    }
  }
  .tipvisible {
    opacity: 1;
    transform: translateX(0); /* 滑动到原位置 */
    pointer-events: auto; /* 允许鼠标交互 */
  }
  .rightNavigator {
    position: fixed;
    top: 25rem;
    right: 2%;
    z-index: 1;
    .rightNavigatorItem1 {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 淡灰色阴影 */
      display: flex; /* 使用flex布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      cursor: pointer; /* 鼠标指针样式 */
      overflow: hidden;
      .connectUs {
        width: 2.2rem;
        height: 2.2rem;
        background-repeat: no-repeat;
        background-image: url("../../assets/spritPic2.png");
        background-position: -93px -51px;
      }
      &:hover {
        background: linear-gradient(90deg, #ff541f, #ff3300);
        .connectUs {
          background-position: -93px -77px;
        }
      }
    }

    .rightNavigatorItem {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 淡灰色阴影 */
      display: flex; /* 使用flex布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      cursor: pointer; /* 鼠标指针样式 */
      overflow: hidden;
      .rightNavItemIcon4 {
        width: 2.2rem;
        height: 2.2rem;
        background-repeat: no-repeat;
        background-image: url("../../assets/spritPic2.png");
        background-position: -143px -50px;
      }
    }
  }
  .navPlaceholder {
    height: 7.5rem;
  }

  .homeTopNav {
    width: calc(100% - 16rem);
    background-color: #fff;
    z-index: 2;
    padding: 2.2rem 8rem 1.4rem 8rem;
    display: flex;
    position: fixed;
    .homeTopNavItem {
      cursor: pointer;
      margin-right: 1.2rem;
      text-align: center;
      position: relative;
      font-size: 1.4rem;
      color: #000;
      border-radius: 100px 100px 100px 100px;
      padding: 1rem 1.8rem;
      &:hover {
        .itemText {
          font-weight: 600;
          background: #efefef;
        }
      }
    }

    .topActive {
      font-weight: 600;
      background: #efefef;
    }
  }

  .homeTopNavBorder {
    border-bottom: 1px solid #f0f0f0;
  }

  .homePicContainer {
    /* 图片展示区域布局 */
    display: grid;
    grid-template-columns: repeat(
      5,
      minmax(10rem, 1fr)
    ); /* 根据页面宽度自适应 n 列 */
    gap: 1.6rem; /* 图片之间的间距 */
    padding: 0 8rem;
    margin-bottom: 2rem;
    .imageItem {
      .image-grid {
        width: 100%;
        aspect-ratio: 4 / 3;
        cursor: pointer;
        overflow: hidden; /* 保证图片放大时不会超出容器范围 */
        position: relative;
        border-radius: 12px;
        &:hover {
          img {
            transform: scale(1.1); /* 鼠标悬浮时图片放大1.1倍 */
          }

          .collectIcon {
            display: block;
            position: absolute;
            z-index: 1;
            width: 3.4rem;
            height: 3.4rem;
            line-height: 3.6rem;
            right: 1.2rem;
            bottom: 1.4rem;
            background: hsla(0, 0%, 100%, 0.8);
            background-size: 4rem 4rem;
            font-size: 2rem;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .collectIconPic {
              width: 2rem;
              height: 2rem;
              border-radius: 0px;
              background: transparent;
              z-index: 1;
            }
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(34, 34, 34, 0.4);
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* 保持图片比例 */
          transition: transform 1s ease-in-out; /* 定义放大动画的过渡效果 */
        }
      }
    }

    .picTitle {
      margin-top: 0.8rem;
      height: 2rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: rgba(34, 34, 34, 0.8);
      line-height: 2rem;
      cursor: pointer;
      &:hover {
        color: #000;
        font-weight: 600;
      }
    }
  }

  .noData {
    display: block;
    margin: 5rem auto;
  }

  .hasNoMore {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
